.menu-config-store{
  font-weight: bold;
}

.input-primary::after {
  content: '*';
  color: red;
  position: absolute;
  top: -8px;
  left: -8px;
}

.menu-config-warning{
  color: red;
  font-weight: bold;
}

.menu-config-warning-upload-not-available{
  color: var(--primary);
  font-weight: bold;
}
