@import '~antd/dist/antd.css';

/* primary: #1890ff */
/* Header Background： #001529 */
/* minimum screen width 870px */

:root{
  --primary: #1890ff;
  --tableHeader: #cbe6ff;
  --tableHover: #e4f2ff;
}

.login-logo-wrap{
  margin-bottom: 30px;
}

.login-page-title h2 {
  text-align: center;
  margin-bottom: 30px !important;
}

.site-layout-content {
  min-width: 1350px;
  min-height: 600px;
  padding: 24px;
  background: #fff;
}

.ant-layout-header{
  height: 40px;
}

.ant-tabs-tab{
  height: 60px;
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.ant-tabs-tab.ant-tabs-tab-active {
  background-color: var(--tableHeader) !important;
  border-left: 5px solid var(--primary) !important;
  z-index: 2;
}

.ant-table-thead > tr >th{
  background:  var(--tableHeader);
  font-weight: bold;
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background-color: var(--tableHover);
}

.ant-table-body .ant-table-row-hover{
  background: var(--tableHover);
}

.ant-table-body .ant-table-row-hover>td{
  background: var(--tableHover);
}

.vt-row{
  padding: 10px;
}

.vt-row:hover{
  background-color: var(--tableHover);
}