.header-wrap {
  background-color: #001529;
  color: white;
  font-weight: bold;
}

.header-top {
  display: flex;
  justify-content: space-between;
  line-height: 40px;
}

.header-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-logo>img{
  height: 25px;
}

.header-logout {
  cursor: pointer;
}

.header-logout:hover {
  color: #1890ff;
}

.header-user {
  display: none;
}
.header-date {
  display: none;
}

@media only screen and (min-width: 768px) {
  .header-user {
    display: block;
  }
  .header-date {
    display: block;
  }
}


