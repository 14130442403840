.vt-button:enabled {
  color: #fff;
  width: '80px';
  height: '35px';
  font-size: '14px';
  background-color: #1890ff;
  box-sizing: border-box;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  box-shadow: 0 6px #4674a0;
  cursor: pointer;
  padding: 0 !important
}

.vt-button:active:not([disabled]) {
  transform: translateY(3px);
  box-shadow: 0 3px #4674a0;
}

.vt-button:disabled {
  color: #999;
  width: '80px';
  height: '35px';
  font-size: '14px';
  background-color: #dddddd;
  box-sizing: content-box;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  box-shadow: 0 6px #dddddd;
  cursor: not-allowed;
  padding: 0 !important
}
